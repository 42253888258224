.clearfix
{
  @include clearfix;
}

body
{
	background: #fff;
}

.layout-container
{
	@include outer-container;
	margin: 0 auto;
	padding-left: $block-padding-x;
	padding-right: $block-padding-x;
	max-width: 1200px; 
	
}

.main-container
{
	margin-top: 30px;
	&.with-left-sidebar
	{
		@include clearfix;
		.sidebar
		{
			@include span-columns(3);
			@include bp('sm')
			{
				float: none;
				width: 100%;
				margin: 0;
			}
		}
		main
		{
			@include span-columns(9);	
			float: right;
			margin-left: 2.35765%;
			margin-right: 0;
			@include bp('sm')
			{
				float: none;
				width: 100%;
				margin: 0;
			}
		}
	}
}

.container
{
	margin-left: -$block-padding-x;
	margin-right: -$block-padding-x;
	margin-bottom: $space-y;
	@include clearfix;
}

.fh-container
{
	position: relative;
	overflow: hidden;
	&:after
	{
		content: '';
		display: block;
		margin-top: 100%; 
	}
} 

.full-container
{
	@include position(absolute, 0 0 0 0);
	padding: $block-padding;

}


.filled-block
{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}


