.footer
{
	background: $brand-primary;
	padding: 30px 0;
	margin-top: 50px;
	position: relative;
	@include bp('sm')
	{
		padding: 15px 0;
	}
	&__menu
	{
		@include span-columns(5);
		@include bp('sm')
		{
			@include span-columns(6);
		}
		@include bp('xs')
		{
			display: block;
			width: 100%;
			padding-top: 40px;
		}
		.copyright
		{
			margin-top: 10px;
			color: #ddd;
			margin-bottom: 0;
		}
		ul
		{
			margin: 0;
			padding: 0;
			@include bp('sm')
			{
				display: none;
			}
			li
			{
				display: inline-block;
				margin-right: 30px;
				margin-bottom: 10px;
				a
				{
					color: #fff;
					font-size: 16px;
					text-decoration: none;
					&:hover
					{
						text-decoration: underline;
					}
				}
			}
		}
	}
	&__address
	{
		@include span-columns(2);	
		@include shift(3);
		@include bp('ld')
		{
			@include shift(0);
			@include span-columns(3);	
		}
		@include bp('sm')
		{
			display: none;
		}
		text-align: right;
		p
		{
			color: #fff;
			margin: 0;
			margin-top: 10px;
		}
	}
	&__phone
	{
		margin: 0;
		margin-top: 10px;
		@include bp('sm')
		{
			display: none;
		}
		a
		{
			color: #fff;
			text-decoration: none;
			font-size: 18px;
		}
	}
	&__contacts
	{
		@include span-columns(2);
		@include bp('ld')
		{
			@include span-columns(3);	
			padding-right: 40px;
		}
		@include bp('sm')
		{
			@include span-columns(6);	
			padding: 0;
			text-align: center;
		}
		@include bp('xs')
		{
			display: block;
			position: absolute;
			top: 20px;
		}
		text-align: right;
		.btn
		{
			&:after
			{
				background: darken($brand-slave, 10%);
			}
			&:before
			{
				background: $brand-slave;
			}
		}
	}

	&__search
	{
		position: relative;
		&__input
		{
			padding-right: 20px;
			background: lighten($brand-primary, 10%);
			border: 0;
			padding: 5px 10px;
			color: #fff;
			border-radius: 15px;
			@include transition;
			&:focus
			{
				outline: 0;
				background: #fff;
				color: #000;
				-webkit-box-shadow:inset 0 0 3px 0 rgba(0,0,0,.1);
				box-shadow:inset 0 0 3px 0 rgba(0,0,0,.1);
				& + .footer__search__btn
				{
					color: $brand-primary;
				}
			}
		}
		&__btn
		{
			color: #fff;
			@include transition;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			background: none;
			border: 0;
			width: 30px;
		}
	}

}