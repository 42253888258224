

#sidemenu
{
	ul
	{
		list-style: none;
		padding: 0;
		margin: 0;
		li
		{
			display: block;
			a
			{
				@include transition;
			}
		}
	}
	.menu
	{
		& > li
		{
			margin-bottom: 2px;
			&.current-menu-ancestor,
			&.current-menu-item
			{
				& > .sub-menu
				{
					display: block;
					& > li
					{
						&.current-menu-ancestor,
						&.current-menu-item
						{
							
							ul
							{
								display: block;
							}
						}
					}
				}
				& > a,
				& > a:hover
				{
					background: $brand-slave;
					color: #fff;
				}
			}
			.sub-menu
			{
				padding: 10px 10px 10px 25px;
				display: none;
				.sub-menu
				{
					li
					{
						margin-bottom: 5px;
					}
					a
					{
						display: inline-block;
						font-size: 13px;
						line-height: 1;
					}
					
				}
				a
				{    
					&:hover
					{
						color: $brand-slave !important; 
					}
				}
				& > li
				{
					&.current-menu-ancestor,
					&.current-menu-item
					{
						& > a
						{
							font-weight: bold;
							color: $brand-slave;
						}
					}
					& > a
					{
						color: #777;
						text-decoration: none;
					}
				}
			}
			& > a
			{
				padding: 10px;
				line-height: 1.1;
				background: #eff5f7;

				display: block;
				color: #666;
				text-decoration: none;
				&:hover
				{	
					background: darken(#eff5f7, 5%);
				}
			}
		}
	}
}






.sidebar
{
	.shoppingcart
	{
		background: #f1f1f1;
		border-radius: 5px;
		td
		{
			font-size: 12px;
		}
		a
		{
			color: #000;
		}
	}
	&__section
	{
		padding-bottom: 15px;
		margin-bottom: 15px;
		border-bottom: 1px solid #f1f1f1;
	}
	h2
	{
		font-size: 18px;
		line-height: 1.1;
		text-transform: uppercase;
		margin: 0;
		margin-bottom: 10px;
	}
	ul
	{
		list-style: none;
		padding: 0;
		margin: 0;
		li
		{
			display: block;
			a
			{
				@include transition;
			}
		}
	}
	.wpsc_top_level_categories
	{
		& > li
		{
			margin-bottom: 2px;
			&.wpsc-current-cat
			{
				& > .wpsc_second_level_categories
				{
					display: block;
					& > li
					{
						&.wpsc-current-cat
						{
							
							ul
							{
								display: block;
							}
						}
					}
				}
				& > a,
				& > a:hover
				{
					background: $brand-slave;
					color: #fff;
				}
			}
			.wpsc_second_level_categories
			{
				padding: 10px 10px 10px 25px;
				//display: none;
				ul
				{
					padding-left: 16px;
					margin: 10px 0;
					li
					{
						margin-bottom: 5px;
						line-height: 1;

					}
					a
					{
						display: inline-block;
						font-size: 13px;
						color: #777;
						text-decoration: none;
						
					}
					
				}
				a
				{    
					&:hover
					{
						color: $brand-slave !important; 
					}
				}
				& > li
				{
					&.wpsc-current-cat
					{
						& > a
						{
							font-weight: bold;
							color: $brand-slave;
						}
					}
					& > a
					{
						color: #777;
						text-decoration: none;
					}
				}
			}
			& > a
			{
				padding: 10px;
				line-height: 1.1;
				background: #eff5f7;
				display: block;
				color: #666;
				text-decoration: none;
				&:hover
				{	
					background: darken(#eff5f7, 5%);
				}
			}
		}
	}
}