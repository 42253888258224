/*div#n2-ss-2 .n2-ss-slider-1,
div#n2-ss-2 .n2-ss-slide,
div#n2-ss-2
{
	height: 500px !important;
	@include bp('ld')
	{
		height: 360px !important;
	}
}*/

#tribe-bar-form .tribe-bar-submit input[type=submit]
{
	font-size: 11px !important;
}

#tribe-events-content a, .tribe-events-event-meta a
{
	color: $brand-primary !important;
	&:hover
	{
		text-decoration: underline;
	}
}

.tribe-events-single-event-title
{
	font-size: 36px !important;
	line-height: 1.2; 
}

.tribe-events-schedule 
{
	h2
	{
		margin-top: 0;
		margin-bottom: 0;
		span
		{
			display: inline-block !important;
		}
	}
}

#tribe-events-content .tribe-events-button
{
	color: #fff !important;
}

.tribe-events-cost
{
	font-weight: bold;
	display: block;
	margin-top: 10px !important;
}

main
{
	table
	{
		max-width: 100%;
	}
}

main .default_product_display
{
	border-bottom: 1px solid #f1f1f1;
	padding-bottom: 30px !important; 
}

main .default_product_display,
main .single_product_display
{
	padding: 10px;
	.wpsc_buy_button
	{
		background: $brand-slave;
		color: #fff;
		padding: 10px !important;
		border: 0;
		border-radius: 5px;
		display: inline-block;
		
		@include transition;
		&:hover
		{
			background: darken($brand-slave, 10%);
		}
	}
	

	.prodtitle
	{
		font-size: 18px;
		padding-bottom: 10px;
		display: block;
		position: relative;
	}
	
	.productcol
	{
		@include bp('xs')
		{
			margin-left: 0 !important;
		}
	}

	.imagecol
	{
		position: relative;
		margin-right: 20px;
		text-align: center;
		@include bp('xs')
		{
			width: 100% !important;
			margin-bottom: 20px;
		}
		border: 0;
		a
		{
			border: 0;
			position: relative;
			overflow: hidden;
			display: block;
			background: #efefef;
			border: 1px solid #ccc;
			//padding: 10px;
			img
			{
				margin: auto;
				border: 0 !important;
				background: none !important;
				max-width: 100%;
			}
		}
	}
}

main
{
	a
	{
		color: $brand-primary;
		@include transition;
		&:hover
		{
			color: $brand-slave;
		}
	}

	.sub-menu
	{
		list-style: none;
		margin-left: 0;
		padding: 0;
		.supportNav
		{
			margin-right: 4px;
		}
		ul
		{
			padding-left: 40px;
		}
	}
}

.n2-ss-slide-background
{
	img
	{
		min-width: 100% !important;
		margin: auto !important;
	}
}
.n2-ow
{
	white-space: normal !important;
	@include bp('xs')
	{
		font-size: 20px !important;
	}
}

.nextend-smartslider-button-container
{
	a
	{
		@include bp('xs')
		{
			font-size: 16px !important;
		}	
	}
	
}
.n2-ss-layer
{
	max-width: 80% !important;
}

.b24-widget-button-position-bottom-right
{
	@include bp('md')
	{
		right: 20px !important;
	}
}

.nextend-arrow-next,
.nextend-arrow-previous
{
	top: 50% !important;
}

.nextend-bullet-bar
{
	bottom: 20px !important;
}

.articles-list
{
	@include clearfix(); 
	margin-bottom: 40px;
	margin-top: 20px;
	&__item
	{
		margin-top: 20px;
		margin-bottom: 20px;
		@include omega(4n);
		@include span-columns(3);
		@include bp('md')
		{
			@include span-columns(4);
			@include omega-reset(4n);
			@include omega(3n);
		}
		@include bp('sm')
		{
			@include span-columns(6);
			@include omega-reset(3n);
			@include omega(2n);
		}
		@include bp('xs')
		{
			@include span-columns(12);
			@include omega-reset(2n);
			@include omega(1n);
		}
		a
		{
			text-decoration: none;
		}
		&__image
		{
			position: relative;
			overflow: hidden;
			display: block;
			&:before
			{
				content: ' ';
				display: block;
				@include position(absolute, 0 0 0 0);
				background: $brand-slave;
				z-index: 10;
				opacity: 0;
				@include transition;
				a:hover &
				{
					opacity: .4;
				}
			}
			&:after
			{
				content: ' ';
				display: block;
				margin-top: 100%;
			}
			img
			{
				@include position(absolute, 0 0 0 0);
				margin: auto;
				min-height: 100%;
			}
		}
		&__title
		{
			font-size: 20px;
			margin-top: 10px;
			color: #000;
			text-align: center;
			display: block;
			a:hover &
			{
				color: $brand-slave;
			}
		}
	}
}

.blog-list
{
	@include clearfix(); 
	margin-bottom: 40px;
	margin-top: 20px;
	&__image
	{
		float: left;
		img
		{
			max-width: 200px;
			width: 100% !important;
			height: auto !important;
			margin-right: 20px;
			margin-bottom: 20px;

		}
	}
	&__item
	{
		@include clearfix(); 
		margin-top: 20px;
		margin-bottom: 20px;
		
		a
		{
			text-decoration: none;
		}
		&__image
		{
			position: relative;
			overflow: hidden;
			display: block;
			&:before
			{
				content: ' ';
				display: block;
				@include position(absolute, 0 0 0 0);
				background: $brand-slave;
				z-index: 10;
				opacity: 0;
				@include transition;
				a:hover &
				{
					opacity: .4;
				}
			}
			&:after
			{
				content: ' ';
				display: block;
				margin-top: 100%;
			}
			img
			{
				@include position(absolute, 0 0 0 0);
				margin: auto;
				min-height: 100%;
			}
		}
		&__title
		{
			font-size: 20px;
			margin-top: 10px;
			color: #000;
			text-align: center;
			display: block;
			a:hover &
			{
				color: $brand-slave;
			}
		}
	}
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper object,
.videoWrapper iframe,
.videoWrapper embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


main
{
	h1,
	h2,
	h3,
	h4
	{
		text-align: left !important;
		span
		{
			display: block;
		}
	}
	h1
	{
		color: #000;
		font-size: 36px;
		margin-top: 0;
		line-height: 1.2;
		margin-bottom: 25px;
		a
		{
			color: inherit;
			text-decoration: none;
		}
	}
	h2
	{
		color: #000;
		font-size: 26px;
		margin-top: 20px;
		margin-bottom: 15px;
	}
}