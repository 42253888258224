// Gray colors prototype
$gr1:                         #f2f2f2;
$gr2:                         #e5e5e5;
$gr3:                          #cacaca;
 
// Theme colors
$brand-primary:               #8f480a;
$brand-primary-text:          #fff;  
$brand-slave:                 #EF7F1A;
$brand-slave-text:            #fff;
$text-color:                  #111;

// Gray colors
$gray:                        #e4e4e4;
$gray-light:                  #f2f2f2;
$gray-dark:                   #595959;

// Alert colors
$good-light:                  #b9e7af;
$good-dark:                   #5ba64e;
$bad-light:                   #fdcbbf;
$bad-dark:                    #f62e00;
$neutral-light:               #f6f5be;
$neutral-dark:                #594539;

// Font settings
$font-size-default:           14px;
$font-body:                   'Open Sans', sans-serif;
$font-heading:                'Open Sans', sans-serif;
$font-margin:                 21px;
$font-scale:                  1.608;

// Link settings
$link-color:                  blue;
$link-hover-color:            red;

// Block settings
$space-y:                     1rem;
$input-padding:               6px 12px;
$button-padding:              5px 16px;
$block-padding-x:             15px;
$block-padding-y:             10px;
$block-padding:               $block-padding-y $block-padding-x;


$grid-breakpoints: (
  'xs':  460px,
  'sm':  768px,
  'md':  980px,
  'ld':  1200px,
) !default;
