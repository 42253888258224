/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin bp($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
 
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin bph($height) {
  @media (max-height: $height) {
    @content;
  }
}

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter($grid-columns, $gutter); }
  &:nth-child(#{$nth}+1) { clear: none }
}