body
{
	&.mobile-nav
	{
		#mobile-nav
		{
			-webkit-transform: translateX(0);
			    -ms-transform: translateX(0);
			    transform: translateX(0);
		}
		.mobile-nav-close
		{
			position: absolute;
			top: 10px;
			right: 20px;
			font-size: 30px;
			padding: 0;
			border: 0;
			background: none;
			color: #fff;
		}
	}
	.main-wrapper
	{
		position: relative;
		&:before
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0,0,0,0.5);
			z-index: 100;
			opacity: 0;
			visibility: hidden;
			.mobile-wrapper &
			{
				opacity: 0;
				visibility: hidden;
			}
		}
	}
	#mobile-nav
	{
		position: fixed;
		-webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,.3);
		box-shadow: 0 0 15px 0 rgba(0,0,0,.3);
		top: 0;
		left: 0;
		width: 250px;
		height: 100%;
		z-index: 200;
		overflow-y: auto;
		background: $brand-slave;
		-webkit-transform: translateX(-100%);
		    -ms-transform: translateX(-100%);
		    transform: translateX(-100%);
		@include transition;
		padding-top: 50px;
		ul
		{
			margin: 0;
			padding: 0;
			list-style: none; 
			ul
			{
				display: none;
			}
			li
			{
				display: block;
				border-bottom: 1px solid darken($brand-slave, 5%);
				a
				{
					display: block;
					padding: 10px 30px;
					text-decoration: none;
					color: #fff;
					font-size: 20px;
				}
			}
		}
	}
}

#js-mobile-nav-trigger
{
	display: none;
	@include bp('sm')
	{
		display: inline-block;
		height: 60px;
		font-size: 44px;
		color: #fff;
		background: none;
		border: 0;
	}
}

.header
{
	padding: 20px 0 0;
	position: relative;
	z-index: 50;
	&__mobile
	{
		&__special
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			&__icons
			{
				display: none;
				height: 100%;
				.header__mobile__button
				{
					height: 100%;
					font-size: 36px;
					margin-left: 10px;
					color: #fff;
					border: 0;
					background: none;
					padding: 0;
				}
				@include bp('md')
				{
					display: inline-block;
					width: 170px;
					text-align: right;
					.header__mobile__button
					{
						display: none; 
					}
					#js-mobile-search-trigger
					{
						display: inline-block;
						font-size: 26px;
					}
					
				}
				@include bp('sm')
				{
					text-align: justify;
					&:after
					{
						content: ' ';
						display: inline-block;
						width: 100%;
					}
					.header__mobile__button
					{
						display: inline-block;
						font-size: 28px !important;
					}
				}
			}
		}
	}
	&__logo
	{
		@include span-columns(6);
		@include bp('md')
		{
			@include span-columns(8);
		}
		@include bp('sm')
		{
			@include span-columns(6);	
		}
		a
		{
			display: table;
			color: $brand-primary;
			font-weight: 500;
			line-height: 1.3;
		}
		&__title
		{
			display: inline-block;
			width: 100%;
			font-size: 24px;

			@include bp('ld')
			{
				font-size: 22px; 
			}
			@include bp('sm')
			{
				font-size: 19px; 
				line-height: 1.1;
			}
			@include bp('xs')
			{
				display: none;
			}
		}
		&__brandtext
		{
			display: inline-block;
			width: 100%;
			font-weight: 500;
			font-size: 18px;
			@include bp('sm')
			{
				display: none;
			}
		}
		&__textwrap
		{
			display: table-cell;
			vertical-align: middle;
		}
		&__imagewrap
		{
			display: table-cell;
			vertical-align: middle;
			width: 1%;
			padding-right: 20px;
		}
		img
		{
			height: 77px; 
			display: inline-block;
			vertical-align: middle;
		}
	}
	&__info
	{
		@include span-columns(6);
		text-align: right;
		padding-top: 20px;
		@include bp('md')
		{
			padding-top: 10px;
			@include span-columns(4);
		}
		@include bp('sm')
		{
			padding-top: 25px;
			@include span-columns(6);
		}
		&__content
		{
			color: $brand-primary;
			&__inner
			{
				display: inline-block;
				text-align: left;
				@include bp('md')
				{
					//padding-left: 30px;
					position: relative;
				}
				@include bp('sm')
				{
					display: none;
				}
			}
			i
			{
				font-size: 20px;
				margin-right: 7px;
			}
		}	
		&__phone
		{
			font-size: 18px;
			font-weight: 600;
			margin: 0;
			@include bp('md')
			{
				i
				{
					margin-right: 20px;	
					font-size: 24px;
					vertical-align: middle;
				}
				a
				{
					font-size: 20px;
				}
				
			}
			
			a
			{
				display: inline-block;
				margin-right: 15px;
				color: $brand-primary;
				text-decoration: none; 
				&:last-child
				{
					margin-right: 0;
				}
			}

			@include bp('md')
			{

				a
				{
					margin: 0;
					&:last-child
					{
						display: none;
						
					}
				}
			}
		}
		&__address
		{
			margin: 0;
			padding-left: 4px;
			@include bp('md')
			{
				display: none;
				
				i
				{
					position: absolute;
					top: 0;
					left: 0;
				}
				span
				{
					display: none;
				}
			}
		}	
		&__btnwrap
		{
			float: right;
			margin-left: 20px;
			@include bp('md')
			{
				width: 190px;  
				margin: 0;
				margin-bottom: 10px;
			}
			.btn
			{
				font-size: 18px;
				padding-left: 5px;
				padding-right: 5px;
				@include bp('ld')
				{
					font-size: 14px; 
				}
				@include bp('md')
				{
					width: 100%; 
				}
				@include bp('sm')
				{
					width: auto;
					font-size: 16px;
					padding-left: 20px;
					padding-right: 20px;
				}
			}
		}
	}
	&__search
	{
		position: absolute;
		top: 10px;
		right: 0;
		.sidebar &
		{
			position: relative; 
			top: auto;
			input
			{
				background: #eff5f7;
				color: #777;
				&:focus
				{
					outline: 0;
					background: #fff;
					color: #000;
					-webkit-box-shadow:inset 0 0 3px 0 rgba(0,0,0,.1);
					box-shadow:inset 0 0 3px 0 rgba(0,0,0,.1);
					& + .header__search__btn
					{
						color: #777;
					}
				}
			}
			&__btn
			{
				color: #777;
			}
		}
		@include bp('md')
		{
			.header &
			{
				display: none;
				top: 100%;
				padding: 10px;
				background: $brand-slave;
				left: -15px;
				right: -15px;
				border-radius: 0;	
			}
			
			.sidebar &
			{
				display: block;
			}
		}
		&__input
		{
			padding-right: 20px;
			background: darken($brand-slave, 15%);
			border: 0;
			padding: 5px 10px;
			color: lighten($brand-slave, 15%);
			border-radius: 15px;
			width: 100%;
			@include transition;
			&:focus
			{
				outline: 0;
				background: #fff;
				color: #000;
				-webkit-box-shadow:inset 0 0 3px 0 rgba(0,0,0,.1);
				box-shadow:inset 0 0 3px 0 rgba(0,0,0,.1);
				& + .header__search__btn
				{
					color: $brand-slave;
				}
			}
		}
		&__btn
		{
			color: lighten($brand-slave, 15%);
			@include transition;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			background: none;
			border: 0;
			width: 30px;
			@include bp('md')
			{
				right: 15px;
			}
		}
	}
	&__nav__search
	{
		padding-right: 200px;
		position: relative;
		@include bp('md')
		{
			padding-right: 40px;
		}
	}
	nav
	{
		background: $brand-slave;
		padding: 0;
		margin-top: 20px;

		.container
		{
			margin: 0;
		}

		ul
		{
			margin: 0;
			padding: 0;
			cursor: default;
			.menu-item-has-children:hover
			{
				ul
				{
					opacity: 1;
					visibility: visible;
					-moz-transform: translateY(0);
					-webkit-transform: translateY(0);
					-o-transform: translateY(0);
					-ms-transform: translateY(0);
					transform: translateY(0);
					background: #984e0b;
					li
					{
						&.current-menu-item
						{
							a,
							a:hover
							{
								background: darken(#984e0b, 10%);
							}
						}
						a
						{
							&:hover
							{
								background: darken(#984e0b, 10%);
							}
						}
					}
				}
			}
			ul
			{
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				min-width: 160px;
				background: #e07210; 
				opacity: 0;
				visibility: hidden;
				-moz-transform: translateY(-20px);
				-webkit-transform: translateY(-20px);
				-o-transform: translateY(-20px);
				-ms-transform: translateY(-20px);
				transform: translateY(-20px);
				@include transition;
				li
				{
					width: 100%;
					a
					{
						&:before,
						&:after
						{
							display: none;
						}
						&:hover
						{
							background: darken(#e07210, 10%);
						}
					}
				}
			}
			@include bp('sm')
			{
				
				display: block;

				position: fixed;
			    top: -1px;
				
				background: darken($brand-slave, 10%);
				height: 100%;
				width: 220px;
				left: 0;
				position: fixed;
				z-index: 9999;
				overflow-x: hidden;
				transition: .5s;
				
					-moz-transform: translateX(-100%) translateY(0);
				    -webkit-transform: translateX(-100%) translateY(0);
				    -o-transform: translateX(-100%) translateY(0);
				    -ms-transform: translateX(-100%) translateY(0);
				    transform: translateX(-100%) translateY(0);

				-webkit-box-shadow:inset 0 0 10px 0 rgba(0,0,0,.15);
				box-shadow:inset 0 0 10px 0 rgba(0,0,0,.15);

				height: 100vh;   
				-webkit-overflow-scrolling: touch; 

				.mobile-nav &
				{
						-moz-transform: translateX(0%) translateY(0);
					    -webkit-transform: translateX(0%) translateY(0);
					    -o-transform: translateX(0%) translateY(0);
					    -ms-transform: translateX(0%) translateY(0);
					    transform: translateX(0%) translateY(0);
				}
				li
				{
					width: 100%;
				}
			}
			li
			{
				display: inline-block;
				position: relative;
				&:hover
				{
					a:before
					{
						height: 100%;
					}
				}
				&.current-menu-item,
				&.current-menu-parent
				{
					a
					{
						&:after
						{
							z-index: -1;
							content: ' ';
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 100%;
							-webkit-box-shadow:inset 0 0 4px 0 rgba(0,0,0,.2);
							box-shadow:inset 0 0 4px 0 rgba(0,0,0,.2);
							background: darken($brand-slave, 20%);
							//border-top: 6px solid #fff;
							//margin-bottom: 4px;
						}
					}
				}
				a
				{
					display: block;
					font-size: 18px;
					padding: 15px 10px;
					color: #fff;
					text-decoration: none;
					position: relative;
					z-index: 10;
					@include bp('ld')
					{
						font-size: 16px; 
					}
					@include bp('md')
					{
						padding: 15px 5px;
					}
					
					&:before
					{
						content: ' ';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 0;
						background: darken($brand-slave, 5%);
						z-index: -1;
						@include transition;
					}
					
				}
			}
		}
		
	}
}




#contact_form_pop
{
	overflow: hidden;
	form
	{
		border: 0;
		p
		{
			font-size: 16px;
			text-align: left;
		}
		input,
		textarea
		{
			padding: 6px 15px;
			width: 100%;
			box-sizing: border-box;
			font-size: 15px;
			margin-top: 5px;
			&:focus
			{
				border: 1px solid  $brand-slave;
				outline: 0;
			}
		}
	}
}